// Node Modules
import React from 'react';

// Components
import AssumingRemoteControlHero from '../../components/content-sections/course/AssumingRemoteControlHero';
import CourseContent from '../../components/content-sections/course/CourseContent';
import JoinUsFormWrapper from '../../components/form/presentational/JoinUsFormWrapper';
import Layout from '../../components/core/layout';
import SEO from '../../components/core/seo';

const CourseAssumingRemoteControl = () => (
  <Layout displayStickyButton={false}>
    <SEO title="Assuming Remote Control Course" />
    <AssumingRemoteControlHero />
    <CourseContent
      dripColor="#949999"
      title="Assuming Remote Control Module |&nbsp;12&nbsp;Minutes"
      description="Provides managers with an applicable guide with tips on leading effective virtual&nbsp;meetings."
      renderTopics={() => (
        <ul className="greater-than-list">
          <li>How to Lead Effective Meetings</li>
          <li>Appropriate Technology</li>
          <li>Structure Is Key</li>
          <li>Prep Materials</li>
          <li>Communication and Feedback</li>
          <li>Meeting Notes</li>
          <li>Start and End on Time</li>
          <li>Assuming Remote Control: A Resource Guide for the Virtual Workforce (PDF)</li>
        </ul>
      )} />
    <JoinUsFormWrapper buttonText="Register" cta="Register for Assuming Remote Control" hideCourses hideInterest interest="Register" selectedCourse="assumingRemoteControl" />
  </Layout>
);

export default CourseAssumingRemoteControl;
