// Node Modules
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import Drip from '../../resuables/Drip';

// Styles
import { above, space } from '../../../style/design-system';

const CourseContent = ({ className, dripColor, renderTopics }) => (
  <div className={`${className} position-relative`}>
    <Drip
      className="d-none d-md-block"
      dripHeight="200px"
      dripLeft="10vw"
      dripColor={dripColor} />
    <div className="container">
      <div className="row">
        <div className="col-10 offset-1 col-md-10 offset-md-1 col-lg-4 offset-lg-4 col-xl-6 offset-xl-4">
          <p className="text-left mt-0">Topics covered in this course include:</p>
          {renderTopics()}
        </div>
      </div>
    </div>
  </div>
);

export default styled(CourseContent)`
  padding: 0;
  ${above.md`
    padding: ${space.i} 0;
    p {
      text-align: center;
    }
  `}
`;

CourseContent.propTypes = {
  className: PropTypes.string.isRequired,
  dripColor: PropTypes.string.isRequired,
  renderTopics: PropTypes.func.isRequired,
};
